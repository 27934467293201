import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Contact = () => {
    return (
        <div className="contact__container padding__top__botton">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-2 mx-auto">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact__left">
                                    <div className="section__title__container mb-3">
                                        <h5 className="section__subtitle">Contactez-nous</h5>
                                        <h2 className="section__title">Restons en contact</h2>
                                    </div>
                                    <form>
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-4" id="name" aria-describedby="emailHelp" placeholder="Votre nom" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control mb-4" id="email" placeholder="Votre email" />
                                        </div>
                                        <div className="form-group">
                                            <textarea className="form-control mb-4" name="textarea" id="textarea" cols="30" rows="5" placeholder="Votre message"></textarea>
                                        </div>
                                        <button type="submit" className="btn page__contact__btn">Envoyer le message</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact__right">
                                    <div className="section__title__container mb-3">
                                        <h2 className="section__title">Adresse</h2>
                                        <p className="section__contnet">
                                            Vous avez des questions ou souhaitez en savoir plus sur SchoolSide ? N'hésitez pas à nous contacter via les informations ci-dessous.
                                        </p>
                                    </div>
                                    <div className="address__contact">
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'home']} />
                                            <div className="address__content ml-3">
                                                <p>Adresse :</p>
                                                <p>123 Rue de l'Éducation, Paris, France</p>
                                            </div>
                                        </div>
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'envelope']} />
                                            <div className="address__content ml-3">
                                                <p>Email :</p>
                                                <p>contact@schoolside.fr</p>
                                            </div>
                                        </div>
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'phone-volume']} />
                                            <div className="address__content ml-3">
                                                <p>Numéro de téléphone :</p>
                                                <p>+33 1 23 45 67 89</p>
                                            </div>
                                        </div>
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'clock']} />
                                            <div className="address__content ml-3">
                                                <p>Horaires d'ouverture :</p>
                                                <p>Lun - Ven : 9h - 17h30</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
