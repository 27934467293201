import React from 'react'

const Counter = () => {

    const Counters = [
        {
            counter: 1200,
            title: 'Heures d\'enseignement'
        },
        {
            counter: 1500,
            title: 'Utilisateurs actifs'
        },
        {
            counter: 95,
            title: 'Écoles partenaires'
        },
        {
            counter: 250,
            title: 'Cours proposés'
        }
    ]
    return (
        <div className="counter__wrapper margin__top__bottom">
            <div className="container">
                <div className="counter__container">
                    <div className="row">
                        {Counters.map((Counter, i) =>
                            <div className="col-md-3 col-lg-3" key={i}>
                                <div className="cointer__single">
                                    <h2>{Counter.counter}</h2>
                                    <span>{Counter.title}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Counter
