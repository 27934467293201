import React from 'react'
import sidecolor from '../../assets/images/sidecolor.svg'

const Subscriber = () => {
    return (
        <div className="subscriber__wrapper padding__top__botton margin__top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="subscriber__title__container">
                            <h2 className="subscriber__title">Inscrivez-vous à notre newsletter</h2>
                            <p className="subscriber__contnet">
                                Entrez votre adresse e-mail pour vous inscrire à notre newsletter et recevoir les dernières actualités et mises à jour de SchoolSide !
                            </p>
                        </div>
                        <form className="form-inline">
                            <div className="form-group">
                                <input type="email" className="form-control" id="inputEmail" placeholder="Entrez votre e-mail" />
                                <button type="submit" className="btn">S'inscrire</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="subscriber__con">
                <img src={sidecolor} alt="sidecolor" />
            </div>
        </div>
    )
}

export default Subscriber;
