import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router, Link } from "react-router-dom";
import work1 from '../../assets/images/work1.jpg'
import work2 from '../../assets/images/work2.jpg'
import work3 from '../../assets/images/work3.jpg'
import work4 from '../../assets/images/work4.jpg'
import work5 from '../../assets/images/work5.jpg'
import work6 from '../../assets/images/work6.jpg'

const Service = () => {

    const services = [
        {
            src: work1,
            title: 'Gestion des utilisateurs',
            price: 'Inclus'
        },
        {
            src: work2,
            title: 'Suivi des performances des élèves',
            price: 'Inclus'
        },
        {
            src: work3,
            title: 'Gestion des absences et retards',
            price: 'Inclus'
        },
        {
            src: work4,
            title: 'Suivi des transports scolaires',
            price: 'Inclus'
        },
        {
            src: work5,
            title: 'Communication parents-enseignants',
            price: 'Inclus'
        },
        {
            src: work6,
            title: 'Facturation et comptabilité',
            price: 'Inclus'
        },
    ]
    return (
        <div className="service__wrapper padding__top__botton">
            <div className="side__img__wal">
                <img src="/images/side0.svg" alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-4 mx-auto ">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">Des outils pour chaque école</h5>
                            <h2 className="section__title">Découvrez nos services à SchoolSide</h2>
                            <p className="section__contnet section__margin__bottom">
                                SchoolSide offre une suite complète de services pour simplifier la gestion scolaire, du suivi des élèves à la communication entre parents et enseignants. Découvrez comment nous aidons votre école à mieux fonctionner.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {services.map((service, i) =>
                        <div className="col-lg-4" key={i}>
                            <div className="single__service">
                                <div className="overly__shape"></div>
                                <div className="service__innerimg">
                                    <img src={service.src} className="img-fluid" alt={service.title} />
                                </div>
                                <div className="service__inner__content">
                                    <div className="content__price">
                                        <p>{service.price}</p>
                                    </div>
                                    <h4 className="service__title">
                                        <Router>
                                            <Link to="/details">{service.title}</Link>
                                        </Router>
                                    </h4>
                                    <div className="service__blist__wr">
                                        <ul className="service__blist">
                                            <li><FontAwesomeIcon icon="book" /> 13 Modules</li>
                                            <li><FontAwesomeIcon icon="user" /> 200+ Utilisateurs</li>
                                        </ul>
                                        <ul className="service__blist__one">
                                            <li><FontAwesomeIcon icon="map-marker" />Géré par : Équipe SchoolSide</li>
                                        </ul>
                                    </div>

                                    <div className="service__enroll">
                                        <Router>
                                            <Link to="/enroll">Inscrivez-vous<FontAwesomeIcon icon="angle-right" /></Link>
                                        </Router>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="more__service text-center">
                            <a href="/teachers" className="teacher__btn">Découvrir les atouts <FontAwesomeIcon icon={['fas', 'caret-right']} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;
