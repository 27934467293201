import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider1 from "react-slick";
import { BrowserRouter as Router, Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import slider from '../../assets/images/slider.svg'
import kid1 from '../../assets/images/kid1.svg'
import kid2 from '../../assets/images/kid2.svg'

const Slider = () => {
    const [isOpen, setOpen] = useState(false)
    const [clickVideo, setClickVideo] = useState('KFVdHDMcepw')

    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                className="slick__arrow__next"
                onClick={onClick}
            >
                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
            </div>
        )
    }
    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                className="slick__arrow__prev"
                onClick={onClick}
            >
                <FontAwesomeIcon icon={['fas', 'arrow-left']} />
            </div>
        )
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const SliderContents = [
        {
            h1a: 'SchoolSide',
            h1: 'Solution Innovante',
            p: 'SchoolSide est une solution avancée permettant aux écoles de gérer facilement les utilisateurs. Elle offre un suivi direct des étudiants, parents, entreprises (si CFA), administrateurs, comptables, et même des transports scolaires.',
            vid: 'KFVdHDMcepw'
        },
        {
            h1a: 'SchoolSide',
            h1: 'Suivi personnalisé',
            p: 'Avec SchoolSide, les écoles bénéficient d’un extranet scolaire performant, facilitant la communication entre les différentes parties prenantes, et offrant un suivi pratique et centralisé des activités.',
            vid: ''
        },
        {
            h1a: 'SchoolSide',
            h1: 'Gestion complète',
            p: 'SchoolSide permet une gestion complète des utilisateurs : étudiants, parents, administrateurs, entreprises partenaires, et bien d’autres, tout en assurant un suivi transparent et efficace.',
            vid: 'rzjOLgb2U1U'
        },
        {
            h1a: 'SchoolSide',
            h1: 'Meilleure organisation',
            p: 'Grâce à son interface intuitive, SchoolSide permet aux écoles d’optimiser l’organisation et la gestion des différentes activités, tout en facilitant la collaboration entre les utilisateurs.',
            vid: ''
        },
    ]

    const videoHandle = (vid) => {
        setOpen(true)
        setClickVideo(vid)
    }

    return (
        <div className="slider__wrapper">
            <div className="container">
                <div className="row row__reverce">
                    <div className="col-lg-6">
                        <div className="slider__inner">
                            <ModalVideo
                                channel='youtube'
                                youtube={{ autoplay: 0 }}
                                isOpen={isOpen}
                                videoId={clickVideo}
                                onClose={() => setOpen(false)}
                            />
                            <Slider1 {...settings}>
                                {SliderContents.map((SliderContent, i) =>
                                    <div className="single__slider" key={i}>
                                        <div className="video__modal">
                                            {SliderContent.vid === '' ? '' :
                                                <button className="btn video__btn" onClick={() => videoHandle(SliderContent.vid)}>
                                                    <FontAwesomeIcon icon={['fas', 'play']} />
                                                </button>
                                            }
                                        </div>
                                        <h1>
                                            <span className="above__title">{SliderContent.h1a}</span><br /> {SliderContent.h1}
                                        </h1>
                                        <p className="slider__para">{SliderContent.p}</p>
                                        <div className="slider__btns">
                                            <a href="https://demo.schoolside.fr" className="cmn__btn">Démonstration <FontAwesomeIcon icon={['fas', 'arrow-right']} /></a>
                                        </div>
                                    </div>
                                )}
                            </Slider1>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="slider__image">
                            <img src={slider} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div >
            <div className="slider__side__color">
                <img src={kid2} alt="kid2" />
            </div>
            <div className="slider__side__color1">
                <img src={kid1} alt="kid1" />
            </div>
        </div >
    )
}
export default Slider
