import React from 'react'
import { Link } from "react-router-dom";
import blog from '../../assets/images/blog.jpg'
import blog1 from '../../assets/images/blog1.jpg'
import blog2 from '../../assets/images/blog2.jpg'
import blog3 from '../../assets/images/blog3.jpg'

const BlogEvents = () => {

    const blogs = [
        {
            src: blog,
            title1: '123 Rue de l\'Éducation, Paris, France',
            title2: 'Journée Portes Ouvertes',
            time: '14h30 - 19h30',
            price: 'Gratuit',
        },
        {
            src: blog1,
            title1: '456 Avenue des Sciences, Lyon, France',
            title2: 'Conférence sur l\'Innovation Éducative',
            time: '9h00 - 12h00',
            price: '15€',
        },
        {
            src: blog2,
            title1: '789 Boulevard des Savoirs, Marseille, France',
            title2: 'Atelier Parents-Enseignants',
            time: '10h00 - 16h00',
            price: '25€',
        },
        {
            src: blog3,
            title1: '987 Rue des Technologies, Toulouse, France',
            title2: 'Forum des Métiers du Numérique',
            time: '11h00 - 17h00',
            price: '10€',
        },
        {
            src: blog3,
            title1: '123 Rue de l\'Éducation, Paris, France',
            title2: 'Journée Portes Ouvertes',
            time: '14h30 - 19h30',
            price: 'Gratuit',
        },
        {
            src: blog2,
            title1: '456 Avenue des Sciences, Lyon, France',
            title2: 'Conférence sur l\'Innovation Éducative',
            time: '9h00 - 12h00',
            price: '15€',
        },
        {
            src: blog1,
            title1: '789 Boulevard des Savoirs, Marseille, France',
            title2: 'Atelier Parents-Enseignants',
            time: '10h00 - 16h00',
            price: '25€',
        },
        {
            src: blog,
            title1: '987 Rue des Technologies, Toulouse, France',
            title2: 'Forum des Métiers du Numérique',
            time: '11h00 - 17h00',
            price: '10€',
        }
    ]

    return (
        <div className="blog__events__wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-4 mx-auto">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">Événements à venir</h5>
                            <h2 className="section__title section__margin__bottom">Ne manquez pas nos événements</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {blogs.map((blog, i) =>
                        <div className="col-md-6 col-lg-3" key={i}>
                            <div className="event__blog__single">
                                <div className="event__blog__img">
                                    <img src={blog.src} className="img-fluid" alt={blog.title1} />
                                    <div className="event__date">
                                        <h6 className="event__date1">22</h6>
                                        <h6 className="event__date2">Déc</h6>
                                    </div>
                                </div>
                                <div className="event__blog__content">
                                    <h6 className="event__title1">
                                        <p><b>{blog.title1}</b></p>
                                    </h6>
                                    <h5 className="event__title2">
                                        {blog.title2}
                                    </h5>
                                    <p className="event__time"> {blog.time}</p>
                                    <p className="event__price">{blog.price}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BlogEvents;
