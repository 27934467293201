import React from 'react'
import Bradcrumb from '../../components/breadcrumb/breadcrumb'
import BlogsEvents from '../../components/BlogEvents/BlogEvents'
import LearnAbout from '../../components/LearnAbout/LearnAbout'
import Counter from '../../components/Counter/Counter'

 const AboutUs = ({title})=> {
    return (
        <>
        <Bradcrumb title={title}/>
        <div className="events__wrapper"> 
            <BlogsEvents />
            <LearnAbout/>
            <Counter/>
        </div> 
        </>
    )
}
export default AboutUs