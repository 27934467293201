import React, {useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/style.css';
import './assets/responsive.css';
import Navbar from './Layout/Navbar/Navbar';
import Footer from './Layout/Footer/Footer'
import { BrowserRouter as Router,Switch,Route } from "react-router-dom";
import 'react-modal-video/css/modal-video.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Events from './pages/Events/EventsPage';
import Classes from './pages/Classes/Classes';
import Teachers from './pages/TeacherPage/TeacherPage';
import Contact from './pages/ContactPage/ContactPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import EventDetails from './pages/EventsDetails/EventsDetails';
import TeacherDetails from './pages/TeacherDetails/TeacherDetails';
import ClassesDetails from './pages/ClassesDetails/ClassesDetails';

/* import font */
// basename="/kintergarten"

const App= ()=> {
  useEffect(() => {
    document.title = "SchoolSide"
 }, []);
  return (
    <div className="app">
      <Router>
        <Navbar/>
        <Switch>
          <Route path="/" exact component={() => <Home title="SchoolSide" />}/>
          <Route path="/error" component={() => <ErrorPage title="Erreur" />}/>
          <Route path="/contact" component={() => <Contact title="Contact" />}/>
          <Route path="/aboutus" component={() => <AboutUs title="Nous" />}/>
          <Route path="/teachers" component={() => <Teachers title="Atouts" />}/>
          <Route path="/teacher/details" component={() => <TeacherDetails title="Atouts" title1="details" />}/>
          <Route path="/events" component={() => <Events title="Evenements" />}/>
          <Route path="/classes" component={() => <Classes title="Services" />}/>
          <Route path="/classe/details" component={() => <ClassesDetails title="Services" title1="details" />}/>
          <Route path="/event/details" component={() => <EventDetails title="Evenements" title1="details" />}/>
        </Switch>
        <Footer />
      </Router>
      
    </div>
  );
}

export default App;
