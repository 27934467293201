import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalVideo from 'react-modal-video'
import whyregister from '../../assets/images/whyregister.jpg'
import school from '../../assets/images/school.png'
import teacher from '../../assets/images/teacher.png'
import book from '../../assets/images/book.png'

const WhyRegister = () => {

    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        console.log('Component mounted')
    }, []);

    return (
        <div className="why__register__wrapper padding__bottom">
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col-lg-6">
                        <div className="why__img">
                            <img src={whyregister} alt="whyregister" />
                            <ModalVideo
                                channel='youtube'
                                youtube={{ autoplay: 0 }}
                                isOpen={isOpen}
                                videoId='f02mOEt11OQ'
                                onClose={() => setOpen(false)}
                            />
                            <button className="btn video__btn" onClick={() => setOpen(true)}>
                                <FontAwesomeIcon icon={['fas', 'play']} />
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 why__right__back">
                        <div className="why__right">
                            <div className="section__title__container">
                                <h5 className="section__subtitle1">Pourquoi hésiter à vous inscrire ?</h5>
                                <h2 className="section__title">SchoolSide : le meilleur choix pour votre école</h2>
                                <p className="section__contnet">
                                    Chez SchoolSide, nous sommes passionnés par l'éducation et croyons que chaque étudiant mérite
                                    un environnement d'apprentissage de qualité. 
                                </p>
                            </div>
                            <div className="list__options">
                                <div className="list__one">
                                    <div className="list__one__half">
                                        <img width="50%" src={school} alt="school" />
                                    </div>
                                    <div className="list__one__content">
                                        <h5>Professionnels hautement qualifiés</h5>
                                        <p>Nos équipes sont formées pour accompagner les étudiants tout au long de leur parcours académique et au-delà.</p>
                                    </div>
                                </div>
                                <div className="list__one">
                                    <div className="list__one__half">
                                        <img width="50%" src={teacher} alt="teacher" />
                                    </div>
                                    <div className="list__one__content">
                                        <h5>Approche pédagogique internationale</h5>
                                        <p>SchoolSide intègre les meilleures pratiques pédagogiques issues des programmes internationaux, adaptées à chaque contexte local.</p>
                                    </div>
                                </div>
                                <div className="list__one">
                                    <div className="list__one__half">
                                        <img width="50%" src={book} alt="book" />
                                    </div>
                                    <div className="list__one__content">
                                        <h5>Infrastructure moderne</h5>
                                        <p>Notre plateforme offre une infrastructure robuste et flexible, adaptée aux besoins des établissements scolaires de toutes tailles.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyRegister;
