import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router, Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import Slider1 from "react-slick";
import learnabout from '../../assets/images/learnabout.jpg'

const LearnAbout = () => {

    const [isOpen, setOpen] = useState(false)
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const videos = [
        {
            src: learnabout,
            vid: 'f02mOEt11OQ'
        },
        {
            src: learnabout,
            vid: ''
        },
        {
            src: learnabout,
            vid: 'KFVdHDMcepw'
        },
        {
            src: learnabout,
            vid: ''
        }
    ];

    return (
        <div className="learn__about__wrapper padding__top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="learn__about__img">
                            <Slider1 {...settings}>
                                {videos.map((video, i) =>
                                    <div className="learn__about__img" key={i}>
                                        <img src={video.src} alt="learnabout" />
                                        {
                                            video.vid === '' ? 0 : <>
                                                <ModalVideo
                                                    channel='youtube'
                                                    youtube={{ autoplay: 0 }}
                                                    isOpen={isOpen}
                                                    videoId={video.vid}
                                                    onClose={() => setOpen(false)}
                                                    width='670'
                                                    height='453'
                                                />
                                                <button className="btn video__btn" onClick={() => setOpen(true)}>
                                                    <FontAwesomeIcon icon={['fas', 'play']} />
                                                </button>
                                            </>
                                        }
                                    </div>
                                )}
                            </Slider1>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="learn__right">
                            <div className="up__art">
                                <img src="/images/up.svg" alt="" />
                            </div>
                            <div className="section__title__container">
                                <h5 className="section__subtitle">L'éducation pour tous</h5>
                                <h2 className="section__title">Découvrez la culture de travail chez SchoolSide</h2>
                                <p className="section__contnet">
                                    Chez SchoolSide, nous nous engageons à offrir aux étudiants les outils nécessaires
                                    pour réussir. Nous croyons qu'une bonne éducation est essentielle pour le développement
                                    des compétences académiques et personnelles, avec un environnement propice à l'apprentissage
                                    et à la croissance.
                                </p>
                            </div>
                            <ul className="list__content">
                                <li><FontAwesomeIcon icon="check-circle" />Gestion des transports</li>
                                <li><FontAwesomeIcon icon="check-circle" />Suivi des repas</li>
                                <li><FontAwesomeIcon icon="check-circle" />Activités extérieures</li>
                                <li><FontAwesomeIcon icon="check-circle" />Encadrement de qualité</li>
                            </ul>
                            <p className="learn__contnet2">
                                SchoolSide se consacre à offrir aux écoles et aux étudiants un environnement où chacun peut
                                s'épanouir à travers des activités, des apprentissages et un accompagnement complet.
                            </p>
                            <Router>
                                <h5 className="have__q3">Une question ?
                                    <Link to="/" className="start__free">Commencez gratuitement</Link>
                                </h5>
                            </Router>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LearnAbout;
