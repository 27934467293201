import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/logo.svg'
 const Navbar= ()=> {
    const [addClass, updateClass] = useState(false)
    const [stkyClass, setStkyClass] = useState(false)
    const [mega, setMega] = useState(false)
    const handleClick =()=>{
        updateClass(true)
    }
    const handleClickCross =()=>{
        updateClass(false)
        window.scrollTo(0, 0);
    }
    const scrollHandleScroll=()=>{
        if (window.scrollY > 20) {
            setStkyClass(true)
        }
        else{
            setStkyClass(false)
        }
    }
    const handleMega=()=>{
        setMega(!mega)
    }
    useEffect(() => {
        window.addEventListener('scroll', scrollHandleScroll);
      },[]);
    
    return (
        <>
        <div className="navbar__top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-10 col-xl-6">
                        <ul className="nav__top__left">
                            <li><FontAwesomeIcon icon={['fas', 'envelope']} /> contact@schoolside.fr</li>
                            <li><FontAwesomeIcon icon={['fas', 'home']} /> 123 Rue de l'Éducation, Paris, France</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-2 col-xl-6">
                    <ul className="top__social">
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'facebook-f']}/></a></li>
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'twitter']}/></a></li>
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'linkedin-in']}/></a></li>
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'instagram']}/></a></li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className={"navbar__wrapper"+ ( stkyClass? ' mystky' : '')}>
             <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="navbar__inner_container">
                            <div className="logo">
                                <h4>
                                    {/* <NavLink to="/">Logo</NavLink> */}
                                    <NavLink to="/"><img height="50" src={logo}alt="logo"/></NavLink>
                                </h4>
                            </div>
                            <div className="manu" >  
                                <ul className={addClass? 'vissible' : 'hidden'}>
                                    <li onClick={handleClickCross}><NavLink exact to="/">Accueil</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/events">Evenements</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/teachers">Atouts</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/classes">Services</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/aboutus">Nous</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/contact">Contact</NavLink></li>
                                </ul>
                                <div className={addClass? 'vissible__cross' : 'hidden__corss'} onClick={handleClickCross}>
                                <FontAwesomeIcon icon="times" />
                                </div>
                                <div className="contact__btn">
                                    <a href="https://demo.schoolside.fr"><span className="whats__ap"><FontAwesomeIcon icon={['fa', 'eye']} /></span>Démonstration</a>
                                </div>
                            </div>
                            <div className="mobile__menu" onClick={handleClick}>
                                <span></span>
                               
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        </> 
    )
}
export default Navbar