import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router, Link } from "react-router-dom";

const JoinSession = () => {
    return (
        <div className="joinsession__wrapper margin__bottom">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 offset-sm-0 col-lg-6 text-center offset-lg-5 mx-auto">
                        <h5 className="session__sub">Rejoignez notre prochaine session</h5>
                        <h2 className="session__title">Inscrivez votre école dès aujourd'hui : +33 1 23 45 67 89</h2>

                        <a href="/contact" className="session__join">Prendre rendez-vous <FontAwesomeIcon icon="caret-right" /></a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinSession;
