import React from 'react'
import teacherkid from '../../assets/images/teacherkid.png'
import teacherkid1 from '../../assets/images/teacherkid1.png'
import teacherkid2 from '../../assets/images/teacherkid2.png'
import teacherkid3 from '../../assets/images/teacherkid3.png'

const Teacher1 = () => {
    const features = [
        {
            src: teacherkid,
            title: 'Gestion centralisée'
        },
        {
            src: teacherkid1,
            title: 'Enseignants qualifiés'
        },
        {
            src: teacherkid2,
            title: 'Suivi des performances'
        },
        {
            src: teacherkid3,
            title: 'Communication fluide'
        }
    ]
    return (
        <div className="teacher1__wrapper padding__top__botton">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-4 mx-auto">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">Une solution complète pour votre école</h5>
                            <h2 className="section__title">Les atouts de SchoolSide</h2>
                            <p className="section__contnet section__margin__bottom">
                                SchoolSide est conçu pour simplifier la gestion scolaire. Grâce à notre plateforme, vous pouvez centraliser la gestion des élèves, suivre leurs performances, et favoriser une communication efficace entre les enseignants et les parents.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row last__child_remove">
                    {features.map((feature, i) =>
                        <div className="col-lg-4 col-xl-3 mar__bottom_30" key={i}>
                            <div className="page__teacher__img">
                                <img src={feature.src} alt={feature.title} />
                                <h4>{feature.title}</h4>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Teacher1
