import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router, Link } from "react-router-dom";
import blog from '../../assets/images/blog.jpg'
import blog1 from '../../assets/images/blog1.jpg'
import blog2 from '../../assets/images/blog2.jpg'
import blog3 from '../../assets/images/blog3.jpg'

const Blog = () => {

    const blogs = [
        {
            src: blog,
            title: 'Optimiser la gestion des écoles avec SchoolSide',
            para: 'Découvrez comment notre plateforme aide les administrateurs à améliorer l’efficacité de la gestion quotidienne des écoles et à faciliter la communication avec les parents et les enseignants.'
        },
        {
            src: blog1,
            title: 'Le suivi personnalisé des élèves : un atout clé',
            para: 'SchoolSide permet aux enseignants de suivre en temps réel les progrès des élèves, de générer des rapports personnalisés et de mieux communiquer avec les familles.'
        },
        {
            src: blog2,
            title: 'Simplifier la gestion des transports scolaires',
            para: 'Grâce à SchoolSide, les établissements peuvent mieux gérer les bus scolaires, en assurant la sécurité des élèves et en optimisant les trajets quotidiens.'
        },
        {
            src: blog3,
            title: 'L’importance d’une interface intuitive pour les écoles',
            para: 'Une interface simple et intuitive est essentielle pour garantir que les utilisateurs, qu’ils soient enseignants, parents ou élèves, puissent facilement naviguer sur la plateforme SchoolSide.'
        }
    ]

    return (
        <div className="blog__wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-4 mx-auto ">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">Ensemble, nous innovons</h5>
                            <h2 className="section__title">Articles intéressants mis à jour régulièrement</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {blogs.map((blog, i) =>
                        <div className="col-lg-4 col-xl-3 col-md-6" key={i}>
                            <div className="blog__single">
                                <div className="blog__img">
                                    <img src={blog.src} className="img-fluid" alt={blog.title} />
                                </div>
                                <div className="blog__inner__content">
                                    <h5 className="blog__title">
                                        <b>{blog.title}</b>
                                    </h5>
                                    <p className="blog__para">
                                        {blog.para}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="more__teacher text-center">
                            <a href="/events" className="teacher__btn">Voir les évènements <FontAwesomeIcon icon={['fas', 'caret-right']} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog;
