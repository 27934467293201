import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router, Link } from "react-router-dom";
import teacher01 from '../../assets/images/teacher01.jpg'
import teacher02 from '../../assets/images/teacher02.jpg'
import teacher03 from '../../assets/images/teacher03.jpg'
import teacher04 from '../../assets/images/teacher04.jpg'
import teacher05 from '../../assets/images/teacher05.jpg'
import teacher06 from '../../assets/images/teacher06.jpg'
import teacher07 from '../../assets/images/teacher07.jpg'
import teacher08 from '../../assets/images/teacher08.jpg'

const Teacher = () => {
    const teachers = [
        {
            src: teacher01,
            name: 'Christopher C.',
            title: 'Directeur Général'
        },
        {
            src: teacher02,
            name: 'Alexis D.',
            title: 'Directeur Technique (CTO)'
        },
        {
            src: teacher03,
            name: 'Chahine B.',
            title: 'Intervenant Formation'
        },
        {
            src: teacher04,
            name: 'Noham H.',
            title: 'Chef de Projet'
        },
        {
            src: teacher05,
            name: 'Mélanie D.',
            title: 'Responsable Marketing'
        },
        {
            src: teacher06,
            name: 'Gauthier B.',
            title: 'Client Témoin'
        },
        {
            src: teacher07,
            name: 'Salim A.',
            title: 'Développeur FullStack'
        },
        {
            src: teacher08,
            name: 'Jannatul A.',
            title: 'Graphic Designer'
        },
    ]
    return (
        <div className="teacher__wrapper padding__bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-4 mx-auto ">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">Ensemble, nous façonnons l'avenir</h5>
                            <h2 className="section__title">L'équipe qui soutient vos écoles</h2>
                            <p className="section__contnet section__margin__bottom">
                                Chez SchoolSide, notre équipe est dédiée à offrir les meilleures solutions technologiques pour faciliter la gestion des établissements scolaires, et ainsi améliorer l'expérience des étudiants, enseignants et parents.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {teachers.map((teacher, i) =>
                        <div className="col-lg-4 col-xl-3" key={i}>
                            <div className="teacher__single">
                                <div className="teacher__img">
                                    <img src={teacher.src} className="img-fluid" alt={teacher.title} />
                                </div>
                                <div className="teacher__content">
                                    <div className="teacher__content__in">
                                        <h5>{teacher.name}</h5>
                                        <h6>{teacher.title}</h6>
                                        <div className="teacher__social__wrapper">
                                            <ul className="teacher__social">
                                                <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a></li>
                                                <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'twitter']} /></a></li>
                                                <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></a></li>
                                                <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'instagram']} /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Teacher
