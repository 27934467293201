import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SRLWrapper } from "simple-react-lightbox";
import { Link } from "react-router-dom";
import footer1 from '../../assets/images/footer1.jpg'
import footer2 from '../../assets/images/footer2.jpg'
import footer3 from '../../assets/images/footer3.jpg'
import footer4 from '../../assets/images/footer4.jpg'
import footer5 from '../../assets/images/footer5.jpg'
import footer6 from '../../assets/images/footer6.jpg'
import footer7 from '../../assets/images/footer7.jpg'
import footer8 from '../../assets/images/footer8.jpg'
import service1 from '../../assets/images/service1.jpg'
import teacherkid1 from '../../assets/images/teacherkid.png'
import teacherkid2 from '../../assets/images/teacherkid1.png'

const Footer = () => {
    const [bottomClass, setBottomClass] = useState(false)

    const scrollHandleScroll = () => {
        if (window.scrollY > 500) {
            setBottomClass(true)
        } else {
            setBottomClass(false)
        }
    }

    const footerImages = [
        {
            src: footer1,
            thumbnail: service1
        },
        {
            src: footer2,
            thumbnail: teacherkid1
        },
        {
            src: footer3,
            thumbnail: service1
        },
        {
            src: footer4,
            thumbnail: teacherkid1
        },
        {
            src: footer5,
            thumbnail: teacherkid2
        },
        {
            src: footer6,
            thumbnail: service1
        },
        {
            src: footer7,
            thumbnail: teacherkid1
        },
        {
            src: footer8,
            thumbnail: teacherkid2
        }
    ]

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandleScroll);
    }, []);

    return (
        <div className="footer__wrapper padding__top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="footer__one">
                            <h3 className="footer__title">Contactez-nous</h3>
                            <ul className="footer__list1">
                                <li><FontAwesomeIcon icon={['fas', 'phone-volume']} />Téléphone : +33 1 23 45 67 89</li>
                                <li><FontAwesomeIcon icon={['fas', 'envelope']} />Email : contact@schoolside.fr</li>
                                <li><FontAwesomeIcon icon={['fas', 'home']} />Adresse : 123 Rue de l'Éducation, Paris, France</li>
                                <li><FontAwesomeIcon icon={['fas', 'clock']} />Heures d'ouverture : 9h - 17h, Lundi - Vendredi</li>
                            </ul>
                            <ul className="footer__social">
                                <li><a href="https://www.facebook.com"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a></li>
                                <li><a href="https://www.twitter.com"><FontAwesomeIcon icon={['fab', 'twitter']} /></a></li>
                                <li><a href="https://www.linkedin.com"><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></a></li>
                                <li><a href="https://www.instagram.com"><FontAwesomeIcon icon={['fab', 'instagram']} /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="footer__one">
                            <h3 className="footer__title">Informations</h3>
                            <ul className="footer__list2">
                                <li onClick={() => scrollTop()}><Link to="/"> - Accueil</Link></li>
                                <li onClick={() => scrollTop()}><Link to="/teachers"> - Atouts</Link></li>
                                <li onClick={() => scrollTop()}><Link to="/aboutus"> - À propos</Link></li>
                                <li onClick={() => scrollTop()}><Link to="/contact"> - Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="footer__one">
                            <h3 className="footer__title">Notre Galerie</h3>
                            <ul className="footer__blog__img">
                                <SRLWrapper>
                                    {footerImages.map((footerImage, i) =>
                                        <li key={i}>
                                            <a href={footerImage.thumbnail}>
                                                <img src={footerImage.src} alt={i} />
                                            </a>
                                        </li>
                                    )}
                                </SRLWrapper>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <p className="copy__right">
                            ©2017 SchoolSide - Solution de gestion scolaire. Tous droits réservés.
                        </p>
                    </div>
                </div>
            </div>

            <div className={"bottom__top" + (bottomClass ? ' bottom__fade' : '')} onClick={() => scrollTop()}>
                <FontAwesomeIcon icon={['fas', 'angle-up']} />
            </div>
        </div>
    )
}

export default Footer;
